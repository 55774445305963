<template>
	<div class="body">
		<div class="head" v-if="image" :style="{background: 'url('+ image +')'}" style="background-repeat: no-repeat;background-size: cover;">
			<div class="title">{{$t('information.title')}}</div>
			<div class="txt">{{$t('information.text')}}</div>
		</div>
		<div class="content">
			<div class="button">
				<div class="but" v-for="(item,index) in navList" :key="index"
					:style="{'color':item.color,'background':item.bck}" @mouseenter="onEnterTd(index)"
					@click.stop="updateList(item.id)" @mouseleave="onLeaveTd(index)">{{item.name}}</div>
			</div>
			<div> 
				<div class="cont cursorS" v-for="(item,index) in contentList" :key="index" @click.stop="toUrl(item.id)">
					<div class="icon_image">
						<img :src="item.ne_img"  style="object-fit: ;"/>
						<div class="img_icon" v-if="item.ne_is_guanggao==1">{{$t('newText.msg87')}}</div>
					</div>
					<div>
						<div class="tittle text_2_size">{{item.ne_title}}</div>
						<div class="text">{{item.ne_vice_title}}</div>
						<div class="time">
							<div>{{item.create_time}}</div>
							<div class="source">{{$t('newText.msg7')}}：{{item.ne_source}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "consult",
		data() {
			return {
				navList: [
					// {
					// 	text: "全部资讯",
					// 	show: true,
					// 	color: '#FFFFFF',
					// 	bck: "#932224"
					// },
					// {
					// 	text: "最新资讯",
					// 	show: false,
					// 	color: '#000000',
					// 	bck: ""
					// },
					// {
					// 	text: "赛事通知",
					// 	show: false,
					// 	color: '#000000',
					// 	bck: ""
					// },
				],
				slideshowList: [
					"https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnge9b2f6f61ab1ed276c64e75ca587e3d802b8bcff4071f988a94a933002bd2884",
					"https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnge9b2f6f61ab1ed276c64e75ca587e3d802b8bcff4071f988a94a933002bd2884",
					"https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnge9b2f6f61ab1ed276c64e75ca587e3d802b8bcff4071f988a94a933002bd2884",
				],
				contentList: [],
				index: 0,
				image:""

			}
		},
		mounted() {
			this.navLists()
			this.indormationList()
			this.imageList()
		},
		methods: {
			async imageList() {
				try {
					let _this = this
					let params = {
						position_id:4
					}
					let res = await this.$axios.get('ads/getList', {
						params: params
					});
					if (res.code == 1) {
						this.image =  'https://cultural-creation.oss-cn-beijing.aliyuncs.com/'+res.data[0].image
					}
				} catch (error) {
					console.log(error);
				}
			},
			async navLists() {
				try {
					let _this = this
					let params = {}
					let res = await this.$axios.post('news/newsType', params);
					if (res.code == 1) {
						res.data.unshift({id:0,name:this.$t('newText.msg88')});
						this.navList = res.data;
						this.navList.forEach((item, index) => {
							if (index == 0) {
								this.$set(item, 'show', true)
								this.$set(item, 'color', '#ffffff')
								this.$set(item, 'bck', '#932224')
							} else {
								this.$set(item, 'show', false)
								this.$set(item, 'color', '#000000')
								this.$set(item, 'bck', '')
							}

						})
						this.index = this.navList[0].id;
						console.log(this.navList)
					}
				} catch (error) {
					console.log(error);
				}
			},
			async indormationList() {
				try {
					let _this = this
					let params = {
						page: 1,
						type: this.index
					}
					let res = await this.$axios.get('news/index', {
						params: params
					});
					console.log(res)
					if (res.code == 1) {
						this.contentList = res.data
						// this.$router.push('personalCenter')
					}
				} catch (error) {
					console.log(error);
				}
			},
			toUrl(id) {
				this.$router.push({
					path: 'InFormationDetail',
					query: {
						id: id
					}
				})
			},
			updateList(id) {
				this.index = id,
					this.navList.forEach(item => {
						if (item.id == id) {
							item.show = true
							item.color = '#ffffff'
							item.bck = '#932224'
						} else {
							item.show = false
							item.color = '#000000'
							item.bck = ''
						}
					})
				this.$nextTick(() => {
					this.indormationList()
				})
			},
			//鼠标进入的事件。
			onEnterTd(e) {
				if (this.navList[e].show != true) {
					this.navList[e].color = '#FFFFFF'
					this.navList[e].bck = '#932224'
				}
			},
			//鼠标离开的事件。
			onLeaveTd(e) {
				if (this.navList[e].show != true) {
					this.navList[e].color = '#000000'
					this.navList[e].bck = ''
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.body {
		background-color: #ffffff;
		.head {
			background-repeat: no-repeat;
			background-size: cover;
			height: 560px;
			text-align: left;
			padding: 352px 0 0 0;

			.title {
				width: 1360px;
				margin: 0 auto;
				font-size: 58px;
				color: #ffffff;
				text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
			}

			.txt {
				width: 1360px;
				margin: 0 auto;
				font-size: 35px;
				padding-top: 20px;
				color: #ffffff;
				text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
			}
		}
	}

	.content {
		width: 1360px;
		margin: 0 auto;
		padding: 50px 0;
		.cont:hover {
			background: #f5f5f5;
			transition: all 1s linear 0s;
		}

		@keyframes color-change-4x {
			0% {
				background: #ffffff;
			}

			100% {}
		}

		.cont {
			padding: 52px 0;
			display: flex;
			border-bottom: 1px solid #e4e4e4;
			text-align: left;
			.icon_image{
				position: relative;
				.img_icon{
					background: rgba(255, 255, 255, 0.6);
					color: #FFFFFF;
					font-size: 18px;
					width: 67px;
					height: 30px;
					position: absolute;
					right: 0;
					top: 0;
					line-height: 30px;
					text-align: center;
				}
			}
			img{
				object-fit: cover;
			}
			.time {
				font-size: 22px;
				color: #666666;
				margin-left: 40px;
				display: flex;
				line-height: 25px;

				.source {
					overflow: hidden;
					-webkit-line-clamp: 1;
					text-overflow: ellipsis;
					display: -webkit-box;
					padding-left: 40px;
					-webkit-box-orient: vertical;
				}
			}

			.text {
				font-size: 28px;
				height: 33px;
				color: #666666;
				overflow: hidden;
				-webkit-line-clamp: 1;
				text-overflow: ellipsis;
				display: -webkit-box;
				margin: 50px 0 100px 40px;
				-webkit-box-orient: vertical;
			}

			.tittle {
				font-size: 39px;
				font-weight: 500;
				height: 110px;
				color: #000000;
				margin-left: 40px;
				width: 677px;
			}

			img {
				width: 628px;
				height: 346px;
			}
		}

		.button {
			height: 110px;
			display: flex;
			font-size: 22px;
			.but {
				width: 350px;
				height: 58px;
				line-height: 58px;
				border: 1px solid #979797;
				text-align: center;
				margin: 0 30px 0 0;
				cursor: pointer;
			}
		}
	}
</style>
